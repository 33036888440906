import { useMemo } from "react";
import {
  ProductCategoryGroupKey,
  ProductItemCategoryKey,
} from "~/api/models/ProductCategory";
import { useFetchProductCategories } from "~/api/storyblok/useFetchProductCategories";
import useSelectedProductCategoriesStore from "~/stores/selectedProductCategories/useSelectedProductCategoriesStore";

type HairTreatmentPlanType = "prp" | "ht" | "bundle" | "none";
type SkinTreatmentPlanType = "skin" | "bundle" | "none";
type TreatmentPlanType = "hair" | "skin" | "hs" | "none";

const useTreatmentType = () => {
  const { data: allProductCategories } = useFetchProductCategories();

  const [selectedProductCategories, toggleProductCategory] =
    useSelectedProductCategoriesStore((state) => [
      state.selectedProductCategories,
      state.toggleProductCategory,
    ]);

  const [
    htCategory,
    prpCategory,
    mesotherapyCategory,
    beardCategory,
    eyebrowCategory,
    skinCategory,
  ] = useMemo(() => {
    return [
      ProductItemCategoryKey.ht,
      ProductItemCategoryKey.prp,
      ProductItemCategoryKey.mesotherapy,
      ProductItemCategoryKey.beard,
      ProductItemCategoryKey.eyebrow,
      ProductCategoryGroupKey.skin,
    ].map((key) => {
      const category = allProductCategories
        ?.flatMap((group) => group.options)
        .concat(allProductCategories)
        .find((category) => category.key === key);

      return category;
    });
  }, [allProductCategories]);

  const [
    htSelected,
    prpSelected,
    mesotherapySelected,
    beardSelected,
    eyebrowSelected,
    skinSelected,
  ] = useMemo(() => {
    return [
      ProductItemCategoryKey.ht,
      ProductItemCategoryKey.prp,
      ProductItemCategoryKey.mesotherapy,
      ProductItemCategoryKey.beard,
      ProductItemCategoryKey.eyebrow,
      ProductCategoryGroupKey.skin,
    ].map((key) => {
      const category = selectedProductCategories.find(
        (category) => category.key === key
      );
      return category;
    });
  }, [selectedProductCategories]);

  const hairPlanType: HairTreatmentPlanType = useMemo(() => {
    if (htSelected && prpSelected) {
      return "bundle";
    } else if (htSelected) {
      return "ht";
    } else if (prpSelected) {
      return "prp";
    }
    return "none";
  }, [htSelected, prpSelected]);

  const skinPlanType: SkinTreatmentPlanType = useMemo(() => {
    if (skinSelected && hairPlanType !== "none") {
      return "bundle";
    } else if (skinSelected) {
      return "skin";
    }

    return "none";
  }, [skinSelected, hairPlanType]);

  const planType: TreatmentPlanType = useMemo(() => {
    if (
      (htSelected ||
        prpSelected ||
        mesotherapySelected ||
        beardSelected ||
        eyebrowSelected) &&
      skinSelected
    ) {
      return "hs";
    } else if (
      htSelected ||
      prpSelected ||
      mesotherapySelected ||
      beardSelected ||
      eyebrowSelected
    ) {
      return "hair";
    } else if (skinSelected) {
      return "skin";
    }
    return "none";
  }, [
    htSelected,
    prpSelected,
    mesotherapySelected,
    beardSelected,
    eyebrowSelected,
    skinSelected,
  ]);

  const getProductItemCategoryKeySelected = (
    categoryKey: ProductItemCategoryKey
  ) => {
    switch (categoryKey) {
      case "beard":
        return beardSelected;
      case "eyebrow":
        return eyebrowSelected;
      case "ht":
        return htSelected;
      case "prp":
        return prpSelected;
      case "mesotherapy":
        return mesotherapySelected;
      default:
        return undefined;
    }
  };

  return {
    planType,
    hairPlanType,
    skinPlanType,
    htSelected,
    prpSelected,
    mesotherapySelected,
    beardSelected,
    eyebrowSelected,
    htCategory,
    prpCategory,
    mesotherapyCategory,
    beardCategory,
    eyebrowCategory,
    toggleProductCategory,
    skinCategory,
    skinSelected,
    getProductItemCategoryKeySelected,
  };
};

export default useTreatmentType;
