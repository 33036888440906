import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { motion } from "framer-motion";
import { useFetchTreatmentFocus } from "~/api/storyblok/useFetchTreatmentFocus";
import { ConfigurationAccordions } from "~/components/atoms/Accordion/Accordions.type";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";
import TreatmentFocusOption from "~/components/atoms/TreatmentFocusOption/TreatmentFocusOption";
import ConfigurationAccordion from "~/components/molecules/ConfigurationAccordion/ConfigurationAccordion";
import useConfigurationSectionStore from "~/stores/headConfiguration";
import useTreatmentFocusStore from "~/stores/treatmentFocus/useTreatmentFocusStore";

type TreatmentFocusCardProps = {
  collapsible?: boolean;
  openedAccordion?: ConfigurationAccordions;
  type: "hair" | "skin";
  showTreatmentsButton?: boolean;
  show?: () => void;
};

const TreatmentFocusCard = ({
  openedAccordion,
  collapsible,
  type,
  show,
  showTreatmentsButton,
}: TreatmentFocusCardProps) => {
  const intl = useIntl();
  const { data, isLoading, isError } = useFetchTreatmentFocus(type);
  const skinSections = useConfigurationSectionStore(
    (state) => state.skinSections
  );
  const items = data?.items || [];

  const isOpen = useMemo(
    () => openedAccordion == ConfigurationAccordions.PRP_OR_MESOTHERAPY_ONLY,
    [openedAccordion]
  );
  const [selectedSkinTreatmentFocus, setSelectedSkinTreatmentFocus] =
    useTreatmentFocusStore((state) => [
      state.selectedSkinTreatmentFocus,
      state.setSelectedSkinTreatmentFocus,
    ]);

  useEffect(() => {
    // remove skin focus if it's hidden
    const newFocuses = selectedSkinTreatmentFocus.filter(
      (focus) =>
        !focus.areas?.length ||
        focus.areas?.some((area) => skinSections.includes(area))
    );
    if (newFocuses.length !== selectedSkinTreatmentFocus.length) {
      setSelectedSkinTreatmentFocus(newFocuses);
    }
  }, [skinSections]);

  return (
    <ConfigurationAccordion
      itemValue={ConfigurationAccordions.PRP_OR_MESOTHERAPY_ONLY}
      collapsible={collapsible}
      isOpen={isOpen}
      title={intl.formatMessage({
        id: "configuration.prp.title",
      })}
      openAccordionDescription={intl.formatMessage({
        id: "configuration.prp.description",
      })}
      closedAccordionDescription={intl.formatMessage({
        id: "configuration.prp.description",
      })}
      headerButtonAction={show}
      headerButtonText={
        showTreatmentsButton
          ? intl.formatMessage({ id: "configuration.skin.treatments.cta" })
          : undefined
      }
      customContent={
        <div className="min-h-grafts-form-radio-group flex flex-wrap gap-3">
          {isLoading ? (
            <div className="w-full flex items-center justify-center">
              <SpinnerIcon />
            </div>
          ) : (
            !isError &&
            items.map((item) => {
              if (
                !item.areas ||
                item.areas.length === 0 ||
                item.areas?.some((area) => skinSections.includes(area))
              )
                return (
                  <motion.div
                    key={item._uid}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <TreatmentFocusOption type={type} focus={item} />
                  </motion.div>
                );
            })
          )}
        </div>
      }
    />
  );
};

export default TreatmentFocusCard;
