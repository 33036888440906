import { useEffect, useRef } from "react";
import { UseInitializeProductsArgs } from "~/hooks/useInitializeProducts";
import useSkinBundles from "~/hooks/useSkinBundles";
import useTreatmentType from "~/hooks/useTreatmentType";

const useInitializeBundles = ({
  opportunity,
  loadingOpportunity,
}: UseInitializeProductsArgs) => {
  const { mesotherapySelected } = useTreatmentType();
  const { selectMesotherapyBundle, isLoading: isSkinBundlesLoading } =
    useSkinBundles();
  const hasInitialized = useRef(false);

  useEffect(() => {
    // Only handle the case when there's no bundle in opportunity
    // Let useInitializeSkinProducts handle existing bundles
    if (
      !hasInitialized.current &&
      !loadingOpportunity &&
      !isSkinBundlesLoading &&
      !opportunity?.getBundleId()
    ) {
      if (mesotherapySelected) {
        hasInitialized.current = true;
        selectMesotherapyBundle();
      }
    }
  }, [
    loadingOpportunity,
    isSkinBundlesLoading,
    opportunity,
    mesotherapySelected,
  ]);
};

export default useInitializeBundles;
