import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Copy from "~/components/atom/Copy";
import Badge from "~/components/atoms/Badge/Badge";
import PricingModal from "~/components/molecules/PricingModal/PricingModal";
import PricingModalOption from "~/components/molecules/PricingModalOption/PricingModalOption";
import SkincareProductsModal from "~/components/organisms/SkincareProductsModal/SkincareProductsModal";
import useSkinBundles from "~/hooks/useSkinBundles";
import { BundleRadioGroupOption } from "~/stores/checkout/product";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";

type SkinBundleModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  editable?: boolean;
};

const SkinBundleModal = ({
  open,
  setOpen,
  // editable = true,
}: SkinBundleModalProps) => {
  const intl = useIntl();
  const { bundles, highestPriceRecommendedBundle } = useSkinBundles();
  const { selectedBundle, hasSkinCare } = useCheckoutStore();
  const { setSkinBundleAndHandleSkinCategory } = useSkinBundles();

  const [selected, setSelected] = useState(selectedBundle);
  const [isSkincareProductsModalOpen, setIsSkincareProductsModalOpen] =
    useState(false);
  // const openSkinCareProductModal = () => {
  //   setIsSkincareProductsModalOpen(true);
  // };
  const selectOption = (option: BundleRadioGroupOption | null) => {
    setSelected(option);
  };
  const saveOption = () => {
    setSkinBundleAndHandleSkinCategory(selected);
    setOpen(false);
  };

  const resetSelected = () => {
    setSelected(selectedBundle);
  };

  const onOpenChange = (open: boolean) => {
    if (!open) {
      resetSelected();
    }
    setOpen(open);
  };

  useEffect(() => {
    if (selectedBundle) {
      setSelected(selectedBundle);
    }
  }, [selectedBundle]);

  return (
    <PricingModal
      open={open}
      setOpen={onOpenChange}
      title={intl.formatMessage({ id: "pricing.modal.bundle.title" })}
      buttonText={intl.formatMessage({ id: "actions.save" })}
      buttonAction={saveOption}
    >
      {/* <SkinCareSwitch
        withInfo
        openSkinCareProductModal={openSkinCareProductModal}
        disabled={!editable}
      /> */}
      <SkincareProductsModal
        open={isSkincareProductsModalOpen}
        setOpen={setIsSkincareProductsModalOpen}
      />
      <div className="flex flex-col justify-between align-center gap-4 h-full">
        <div className="grid gap-4">
          <PricingModalOption
            className="animate-fade-in"
            title={intl.formatMessage({
              id: "treatment.pricing.bundle.none",
            })}
            selected={!selected}
            onSelected={() => selectOption(null)}
          />
          {bundles?.map((option, index) => {
            const badge = highestPriceRecommendedBundle?.id === option.id && (
              <Badge className="bg-green-900">
                <Copy id="pricing.modal.recommended" />
              </Badge>
            );

            return (
              <PricingModalOption
                className="animate-fade-in"
                key={index}
                title={option.name}
                subtitle={option
                  .getProducts(hasSkinCare)
                  .map((product) => product.name)
                  .join(", ")}
                selected={option.id === selected?.id}
                onSelected={() => selectOption(option)}
                priceAmount={option.getPrice(hasSkinCare)}
                badge={badge}
              />
            );
          })}
        </div>
      </div>
    </PricingModal>
  );
};

export default SkinBundleModal;
