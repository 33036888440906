import { useMemo } from "react";
import { MESOTHERAPY_PRODUCT_CODES } from "~/api/constants/hair.const";
import { SalesforceBundle } from "~/api/models/Bundle";
import { useFetchBundles } from "~/api/salesForce/useFetchBundles";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import useTreatmentRelatedSkinProductKeys from "./useTreatmentRelatedSkinProductKeys";
import useTreatmentType from "./useTreatmentType";

const useSkinBundles = () => {
  const { data: bundles, isLoading } = useFetchBundles();
  const { setSelectedBundle, selectedBundle } = useCheckoutStore();
  const {
    skinCategory,
    skinSelected,
    mesotherapyCategory,
    mesotherapySelected,
    toggleProductCategory,
  } = useTreatmentType();
  const treatmentRelatedSkinProductKeys = useTreatmentRelatedSkinProductKeys();

  const highestPriceRecommendedBundle = useMemo(
    () =>
      bundles
        ?.filter((bundle) =>
          bundle.isRecommended(treatmentRelatedSkinProductKeys)
        )
        ?.reduce(
          (prev: SalesforceBundle | null, current) =>
            prev && prev.price > current.price ? prev : current,
          null
        ),
    [bundles, treatmentRelatedSkinProductKeys]
  );

  const setSkinBundleAndHandleSkinCategory = (
    bundle: SalesforceBundle | null
  ) => {
    if (bundle) {
      if (
        MESOTHERAPY_PRODUCT_CODES.includes(bundle.productCode) &&
        !mesotherapySelected &&
        mesotherapyCategory
      ) {
        toggleProductCategory(mesotherapyCategory);
      } else if (
        !MESOTHERAPY_PRODUCT_CODES.includes(bundle.productCode) &&
        !skinSelected &&
        skinCategory
      ) {
        toggleProductCategory(skinCategory);
      }
    }
    setSelectedBundle(bundle);
    // This should be refactored so there is no difference between skin and mesotherapy bundles
    if (mesotherapySelected && bundle == null) {
      toggleProductCategory(mesotherapySelected);
    }
  };

  const toggleSkinBundleAndHandleSkinCategory = (
    bundle: SalesforceBundle | null
  ) => {
    setSkinBundleAndHandleSkinCategory(
      selectedBundle?.productCode === bundle?.productCode ? null : bundle
    );
  };

  const selectMesotherapyBundle = () => {
    const mesotherapyBundle =
      bundles?.find((bundle) =>
        MESOTHERAPY_PRODUCT_CODES.includes(bundle.productCode)
      ) ?? null;
    console.log("mesotherapyBundle", mesotherapyBundle);
    if (mesotherapyBundle) {
      setSelectedBundle(mesotherapyBundle);
    }
  };

  const deselectMesotherapyBundle = () => {
    if (
      selectedBundle?.productCode &&
      MESOTHERAPY_PRODUCT_CODES.includes(selectedBundle.productCode)
    ) {
      setSelectedBundle(null);
    }
  };

  return {
    bundles,
    highestPriceRecommendedBundle,
    setSkinBundleAndHandleSkinCategory,
    toggleSkinBundleAndHandleSkinCategory,
    isLoading,
    selectMesotherapyBundle,
    deselectMesotherapyBundle,
  };
};
export default useSkinBundles;
