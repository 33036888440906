import { Opportunity } from "~/api/models/Opportunity";
import useInitializeBundles from "~/hooks/useInitializeBundles";
import useInitializeHairProducts from "~/hooks/useInitializeHairProducts";
import useInitializeSkinProducts from "~/hooks/useInitializeSkinProducts";

export type UseInitializeProductsArgs = {
  opportunity?: Opportunity;
  loadingOpportunity?: boolean;
};

const useInitializeProducts = ({
  opportunity,
  loadingOpportunity,
}: UseInitializeProductsArgs) => {
  useInitializeHairProducts({ opportunity, loadingOpportunity });
  useInitializeSkinProducts({
    opportunity,
    loadingOpportunity,
  });
  useInitializeBundles({
    opportunity,
    loadingOpportunity,
  });
};

export default useInitializeProducts;
