import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CalculatePrice, PricingBreakdown } from "~/api/models/PriceCalculator";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import { QueryKeys } from "~/queryKeys";
import { CustomError } from "~/services/CustomError";
import {
  INVALID_PUBLIC_DISCOUNT_CODE_ERROR_NAME,
  INVALID_REFERRAL_CODE_ERROR_NAME,
  NO_TOKEN_ERROR_STATUS_CODE,
} from "~/services/errors.const";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { notifyOnce } from "~/utils/toast.utils";

const CALCULATION_ERROR_KEY = "useCalculatePriceError";
const REMOVE_DISCOUNT_CODE_KEY = "removedDiscountCode";
const REMOVE_REFERRAL_CODE_KEY = "removedReferralCode";

type useCalculatePriceArgs = {
  id: string;
  body: CalculatePrice;
  disabled?: boolean;
};

export const useCalculatePrice = ({
  id,
  body,
  disabled,
}: useCalculatePriceArgs) => {
  const { data } = useFetchAccessToken();
  const queryClient = useQueryClient();
  const { locale } = useLocaleStore();
  const [setDiscountCode, setReferralCode] = useCheckoutStore((state) => [
    state.setDiscountCode,
    state.setReferralCode,
  ]);

  return useQuery({
    enabled:
      !!data?.ensureAccessTokenIsValid?.() &&
      (!!body.products.length || !!body.selectedBundle) &&
      !disabled,
    queryKey: [QueryKeys.useCalculatePrice, id, body],
    queryFn: () => {
      return salesforceServiceSingleton.calculatePricing(
        data?.accessToken ?? "",
        id,
        body,
        locale
      );
    },
    onError: (error: CustomError) => {
      if (error.cause !== NO_TOKEN_ERROR_STATUS_CODE) {
        if (error.message.includes(INVALID_PUBLIC_DISCOUNT_CODE_ERROR_NAME)) {
          setDiscountCode(null);
          notifyOnce(
            {
              toastType: "error",
              descriptionId: "pricing.modal.discount.discountCode.error",
            },
            CALCULATION_ERROR_KEY
          );
          notifyOnce(
            {
              toastType: "info",
              descriptionId: "pricing.modal.discount.discountCode.info",
            },
            REMOVE_DISCOUNT_CODE_KEY
          );
        }
        if (error.message.includes(INVALID_REFERRAL_CODE_ERROR_NAME)) {
          setReferralCode(null);
          notifyOnce(
            {
              toastType: "error",
              descriptionId: "pricing.modal.discount.referralCode.error",
            },
            CALCULATION_ERROR_KEY
          );
          notifyOnce(
            {
              toastType: "info",
              descriptionId: "pricing.modal.discount.referralCode.info",
            },
            REMOVE_REFERRAL_CODE_KEY
          );
        }
        notifyOnce(
          {
            toastType: "error",
            descriptionId: "treatment.pricing.error",
          },
          CALCULATION_ERROR_KEY
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.useFetchOpportunity]);
    },
    select: (data) => new PricingBreakdown(data),
  });
};
