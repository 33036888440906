import { useRef } from "react";
import { useIntl } from "react-intl";
import { useFetchIntroCards } from "~/api/storyblok/useFetchIntroCards";
import BlurCard from "~/components/atoms/BlurCard/BlurCard";
import IntroCard from "~/components/molecules/IntroCard/IntroCard";
import MainIntroCard from "~/components/molecules/MainIntroCard/MainIntroCard";
import { Header } from "~/components/organisms/layout/Header/Header";
import useElementPosition from "~/hooks/useElementPosition";

const WhyHS = () => {
  const intl = useIntl();
  const elementRef = useRef<HTMLDivElement | null>(null);
  const { top, left } = useElementPosition(elementRef);
  const { data } = useFetchIntroCards();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [first, second, third] = data ?? [];

  return (
    <div className="flex flex-col gap-header p-body h-full overflow-hidden">
      <Header
        title={intl.formatMessage({ id: "introduction.title" })}
        hasAnimation
      />
      <div className="grid h-full w-full grid-cols-8 gap-6">
        {/* <BlurCard className="col-span-2">
          {first && (
            <IntroCard
              centered
              initial={{ y: "100vh" }}
              animate={{ y: 0 }}
              {...first}
              minTextWidthFit
            />
          )}
        </BlurCard> */}
        <div className="col-span-4" ref={elementRef}>
          <BlurCard className="">
            {top !== 0 && left !== 0 && <MainIntroCard top={top} left={left} />}
          </BlurCard>
        </div>
        <BlurCard className="col-span-4 row-span-2">
          {second && (
            <IntroCard
              centered
              initial={{ x: "50vw" }}
              animate={{ x: 0 }}
              {...second}
            />
          )}
        </BlurCard>
        <BlurCard className="col-span-4">
          {third && (
            <IntroCard
              initial={{ y: "100vh" }}
              animate={{ y: 0 }}
              centered
              {...third}
            />
          )}
        </BlurCard>
      </div>
    </div>
  );
};

export default WhyHS;
