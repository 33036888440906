import { useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import {
  ProductCategoryDropdownOption,
  ProductItemCategoryKey,
  ProductCategoryKey,
} from "~/api/models/ProductCategory";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import { useFetchProductCategories } from "~/api/storyblok/useFetchProductCategories";
import headerDropdownIcon from "~/assets/icons/headerDropdownIcon.png";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonContentType,
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import Dropdown from "~/components/atoms/Dropdown/Dropdown";
import ConfirmModal from "~/components/molecules/ConfirmModal/ConfirmModal";
import useSkinBundles from "~/hooks/useSkinBundles";
import useSelectedProductCategoriesStore from "~/stores/selectedProductCategories/useSelectedProductCategoriesStore";

const FEMALE_DISABLED_CATEGORIES: ProductCategoryKey[] = [
  ProductItemCategoryKey.beard,
];
type HSDropdownProps = {
  disabled?: boolean;
};

const HSDropdown = ({ disabled = false }: HSDropdownProps) => {
  const intl = useIntl();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [clickedCategory, setClickedCategory] = useState<
    ProductCategoryDropdownOption | undefined
  >(undefined);

  const { opportunityId } = useParams();
  const { data: opportunity } = useFetchOpportunity({
    id: opportunityId as string,
  });
  const { data: allProductCategories } = useFetchProductCategories();

  const [selectedProductCategories, toggleProductCategory] =
    useSelectedProductCategoriesStore((state) => [
      state.selectedProductCategories,
      state.toggleProductCategory,
    ]);

  const { selectMesotherapyBundle, deselectMesotherapyBundle } =
    useSkinBundles();

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const handleClickedCategory = (category: ProductCategoryDropdownOption) => {
    if (!disabled) {
      const clickedOnSelectedCategory = selectedProductCategories.find(
        (v) => v.id === category.id
      );
      if (clickedOnSelectedCategory) {
        setClickedCategory(category);
        openConfirmationModal();
      } else {
        setClickedCategory(category);
        toggleProductCategory(category);
        if (category.key === ProductItemCategoryKey.mesotherapy) {
          selectMesotherapyBundle();
        }
      }
    }
  };

  const removeProductCategory = () => {
    if (clickedCategory) {
      toggleProductCategory(clickedCategory);
      if (clickedCategory.key === ProductItemCategoryKey.mesotherapy) {
        deselectMesotherapyBundle();
      }
      setConfirmationModalOpen(false);
    }
  };

  return (
    <>
      <Dropdown
        hasArrow={false}
        align="end"
        value={selectedProductCategories}
        onChange={handleClickedCategory}
        customTrigger={
          <Button
            variant={ButtonVariant.secondaryOutline}
            customIcon={
              <img
                src={headerDropdownIcon}
                alt="capilar icon"
                className="h-6 w-6 "
              />
            }
            contentType={ButtonContentType.icon}
            size={ButtonSize.sm}
            className="p-2"
          />
        }
        optionGroups={
          opportunity?.gender === "female"
            ? allProductCategories?.map((c) => ({
                ...c,
                options: c.options.filter(
                  (o) => FEMALE_DISABLED_CATEGORIES.indexOf(o.key) === -1
                ),
              }))
            : allProductCategories
        }
      />
      <ConfirmModal
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        title={intl.formatMessage({
          id: "header.products.confirm.title",
        })}
        description={intl.formatMessage({
          id: "header.products.confirm.description",
        })}
        buttonText={intl.formatMessage({
          id: "actions.remove",
        })}
        buttonAction={removeProductCategory}
      />
    </>
  );
};

export default HSDropdown;
