import { SalesforceBundle } from "~/api/models/Bundle";
import { HairProductKey } from "~/api/models/HairProductKey";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import { DropdownOption } from "~/components/atoms/Dropdown/Dropdown.types";
import { RadioGroupOption } from "~/components/molecules/RadioGroup/RadioGroup.types";

const botoxAfterTreatmentCodes = [
  "BOT02NACH",
  "BOT03NACH",
  "BOT01NACH",
  "BOTNACH",
  "BOT04NACH",
];
export type ProductRadioGroupOption = RadioGroupOption & Product;
export type BundleRadioGroupOption = RadioGroupOption & SalesforceBundle;
export type ProductDropdownOption = DropdownOption & Product;
const getProductCardKey = (
  product: Product
): SkinProductKey | HairProductKey => {
  let productCardKey: SkinProductKey | HairProductKey = HairProductKey.hair;

  switch (product.family) {
    case "prp hair":
    case "hair transplant":
      if (product.type === "prp hair free") return HairProductKey.freePrp;
      productCardKey = HairProductKey.hair;
      break;

    case "beard transplant":
      productCardKey = HairProductKey.beard;
      break;

    case "eyebrow transplant":
      productCardKey = HairProductKey.eyebrows;
      break;

    case "botox":
      if (botoxAfterTreatmentCodes.includes(product.productCode)) {
        productCardKey = SkinProductKey.botoxAfterTreatment;
      } else if (
        product.type === "sweat botox x1" ||
        product.type === "sweat botox x2"
      ) {
        productCardKey = SkinProductKey.botoxSweat;
      } else if (product.type === "botox x1" || product.type === "botox x2") {
        productCardKey = SkinProductKey.botoxWrinkles;
      } else if (product.type === "botox x4") {
        productCardKey = SkinProductKey.botoxBundle;
      }
      break;

    case "needling":
      productCardKey = SkinProductKey.needling;
      break;

    case "filler":
      if (product.type.includes("lips")) {
        productCardKey = SkinProductKey.lipsFiller;
      } else if (product.type.includes("cheeks")) {
        productCardKey = SkinProductKey.cheeksFiller;
      } else if (product.type.includes("jawline")) {
        productCardKey = SkinProductKey.jawlineFiller;
      } else if (product.type.includes("chin")) {
        productCardKey = SkinProductKey.chinFiller;
      } else if (product.type === "hyaluronidase") {
        productCardKey = SkinProductKey.hyaluronidase;
      } else if (product.type.includes("general")) {
        productCardKey = SkinProductKey.generalFiller;
      }
      break;

    case "skin booster":
      productCardKey = SkinProductKey.skinBooster;
      break;
  }

  return productCardKey;
};
export class Product {
  public readonly title: string;
  public readonly productCardKey: SkinProductKey | HairProductKey;

  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly family: ProductFamily,
    public readonly type: ProductType,
    public readonly recommended: boolean,
    public readonly grafts: {
      from: number;
      to: number;
    },
    public readonly price: number,
    public readonly sessions: number,
    public readonly isLongHair: boolean,
    public readonly productCode: string,
    public readonly areas: number,
    public readonly isHiddenFromSales: boolean
  ) {
    this.title = this.name;
    this.productCardKey = getProductCardKey(this);
  }
}

export const MAX_GRAFT_COUNT_TO_SHOW_LONG_HAIR_OPTION = 3501;

export type ProductFamily =
  | "prp hair"
  | "hair transplant"
  | "beard transplant"
  | "eyebrow transplant"
  | "prp eyebrows"
  | "prp beard"
  | "botox"
  | "needling"
  | "filler"
  | "skin booster"
  | "mesotherapy";
export type ProductType =
  | "bundle"
  | "prp hair"
  | "ht 1 sections long"
  | "ht 1 sections short"
  | "ht 2 sections long"
  | "ht 2 sections short"
  | "ht 3 sections short"
  | "eyebrows"
  | "barttransplantation"
  | "prp eyebrows"
  | "prp beard"
  | "needling"
  | "botox x1"
  | "botox x2"
  | "sweat botox x1"
  | "sweat botox x2"
  | "hyaluronidase"
  | "skinbooster ha"
  | "filler lips"
  | "filler cheeks"
  | "filler jawline"
  | "filler chin "
  | "filler lips addition"
  | "filler cheeks addition"
  | "filler jawline addition"
  | "filler chin addition"
  | "filler general"
  | "botox x4"
  | "prp hair free"
  | "mesotherapy";
