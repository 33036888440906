import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useCalculatePrice } from "~/api/salesForce/useCalculatePrice";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import Copy from "~/components/atom/Copy";
import Button from "~/components/atoms/Button/Button";
import Headline from "~/components/atoms/Headline/Headline";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import EditableDiscount from "~/components/molecules/EditableDiscount/EditableDiscount";
import EditablePaymentMethod from "~/components/molecules/EditablePaymentMethod/EditablePaymentMethod";
import EditableSpecialConditions from "~/components/molecules/EditableSpecialConditions/EditableSpecialConditions";
import PricingCardItem from "~/components/molecules/PricingCardItem/PricingCardItem";
import PricingDropDown from "~/components/molecules/PricingDropdown/PricingDropdown";
import useRequestCreateContract from "~/hooks/useRequestCreateContract";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import { cn } from "~/utils";
import ContractLoader from "../../ContractLoader/ContractLoader";

const PricingCard = () => {
  const intl = useIntl();
  const { opportunityId } = useParams();
  const [
    selectedProducts,
    selectedBundle,
    hasSkinCare,
    paymentMethod,
    discountCode,
    referralCode,
    specialConditionsValues,
    otherSpecialConditions,
  ] = useCheckoutStore((state) => [
    state.selectedProducts,
    state.selectedBundle,
    state.hasSkinCare,
    state.paymentMethod,
    state.discountCode,
    state.referralCode,
    state.specialConditionsValues,
    state.otherSpecialConditions,
  ]);

  const { data: opportunity } = useFetchOpportunity({
    id: opportunityId as string,
    hasRefetch: true,
  });
  const { data: pricing, isLoading } = useCalculatePrice({
    id: opportunityId as string,
    body: {
      products: selectedProducts,
      selectedBundle: selectedBundle,
      hasSkinCare: hasSkinCare,
      paymentMethod: paymentMethod ?? null,
      discountCode: discountCode,
      referralCode: referralCode,
      specialConditions: specialConditionsValues ?? [],
      otherSpecialConditions,
    },
    disabled: !opportunity?.isValidForSales,
  });
  const hasProducts = useMemo(
    () => selectedProducts.length > 0 || selectedBundle,
    [selectedProducts.length, selectedBundle]
  );

  const opportunityHasFreshPriceOrSamePrice = useMemo(() => {
    if (!opportunity?.lastModifiedDateTime || !pricing?.lastModifiedDateTime) {
      return false;
    }
    return (
      opportunity?.lastModifiedDateTime.getTime() >=
        pricing?.lastModifiedDateTime.getTime() ||
      opportunity?.amount === pricing?.totalAmount ||
      !opportunity.isValidForSales
    );
  }, [
    opportunity?.lastModifiedDateTime,
    pricing?.lastModifiedDateTime,
    opportunity?.amount,
    pricing?.totalAmount,
    opportunity?.isValidForSales,
  ]);

  const showDiscountedAmount = useMemo(() => {
    return (
      opportunity?.discountedAmount !== pricing?.totalAmount &&
      opportunityHasFreshPriceOrSamePrice
    );
  }, [
    opportunity?.discountedAmount,
    pricing?.totalAmount,
    opportunityHasFreshPriceOrSamePrice,
  ]);

  const showDiscountReason = useMemo(() => {
    return opportunity?.discountReason && opportunityHasFreshPriceOrSamePrice;
  }, [opportunity?.discountReason, opportunityHasFreshPriceOrSamePrice]);

  const { onCreateContract, requestingContract, composingContract } =
    useRequestCreateContract({
      opportunityId: opportunityId as string,
      selectedProducts,
      selectedBundle,
      paymentMethod,
    });

  return (
    <div className="flex flex-col gap-6 bg-beige-100 rounded-2xl p-6 justify-between flex-1">
      <ContractLoader showLoader={composingContract || requestingContract} />
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 justify-between items-center">
          <Headline level={4}>
            <Copy id="pricing.card.total" />
          </Headline>
          {opportunity?.isValidForSales && <PricingDropDown />}
        </div>
        <div className="flex flex-col gap-6">
          {isLoading && hasProducts && opportunity?.isValidForSales ? (
            <SpinnerIcon />
          ) : (
            <div className="flex gap-8 items-center justify-between">
              <Headline
                level={5}
                className={cn({
                  "text-grey-400 line-through": showDiscountedAmount,
                })}
              >
                {intl.formatMessage(
                  { id: "pricing.amount" },
                  {
                    amount: hasProducts
                      ? !opportunity?.isValidForSales
                        ? opportunity?.amount
                        : pricing?.totalAmount
                      : 0,
                  }
                )}
              </Headline>
              <Headline
                level={5}
                className={cn(
                  "text-grey-700 opacity-0 transition-all duration-300",
                  {
                    "opacity-100": showDiscountedAmount,
                  }
                )}
              >
                {intl.formatMessage(
                  { id: "pricing.amount" },
                  { amount: opportunity?.discountedAmount }
                )}
              </Headline>
            </div>
          )}
          {hasProducts && (
            <div className="flex flex-col gap-3">
              <PricingCardItem
                title={intl.formatMessage({
                  id: "pricing.card.paymentMethod",
                })}
              >
                <EditablePaymentMethod
                  amount={
                    showDiscountedAmount
                      ? opportunity?.discountedAmount
                      : !opportunity?.isValidForSales
                        ? opportunity?.amount
                        : pricing?.totalAmount
                  }
                  editable={opportunity?.isValidForSales}
                />
              </PricingCardItem>
              <PricingCardItem
                title={intl.formatMessage({
                  id: "pricing.card.discount",
                })}
              >
                <EditableDiscount
                  showDiscountedAmount={showDiscountedAmount}
                  discountPercent={opportunity?.discountPercent}
                  discountTotal={opportunity?.discountTotal}
                  editable={opportunity?.isValidForSales}
                />
              </PricingCardItem>
              {showDiscountReason && (
                <PricingCardItem
                  title={intl.formatMessage({
                    id: "pricing.card.discount.reason",
                  })}
                >
                  <Subtitle level={3}>{opportunity?.discountReason}</Subtitle>
                </PricingCardItem>
              )}
              <PricingCardItem
                title={intl.formatMessage({
                  id: "pricing.specialConditions.title",
                })}
              >
                <EditableSpecialConditions
                  editable={opportunity?.isValidForSales}
                />
              </PricingCardItem>
            </div>
          )}
        </div>
      </div>
      {opportunity?.isValidForSales && (
        <Button
          text={intl.formatMessage({ id: "pricing.card.cta" })}
          className="w-full max-w-full"
          onClick={onCreateContract}
        />
      )}
    </div>
  );
};

export default PricingCard;
