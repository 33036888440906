import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import search from "~/assets/images/search.png";
import Copy from "~/components/atom/Copy";
import { DropdownOption } from "~/components/atoms/Dropdown/Dropdown.types";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import useClickOutside from "~/hooks/useClickOutside";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";
import SearchDropdownOption from "./SearchDropdownOption";

export const ITEM_HEIGHT_REM = 4.5;
const DROPDOWN_VERTICAL_PADDING_REM = 0.5;
const DROPDOWN_BORDER_WIDTH_REM = 0.25;

type SearchDropdownProps<T extends DropdownOption> = PropsWithClassName<{
  options: T[];
  titleFormatter?: (option?: T) => string;
  customOptionFormatter?: (option?: T) => React.ReactNode;
  customTriggerFormatter?: (
    option?: T,
    inputValue?: string,
    open?: boolean
  ) => React.ReactNode;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  maxVisibleOptions?: number;
  selectedOption?: T;
  setSelectedOption: (option?: T) => void;
  defaultSelectedOption?: T;
  disabled?: boolean;
  placeholder?: string;
  loadingOptions?: boolean;
}>;

const SearchDropdown = <T extends DropdownOption>({
  className,
  options,
  titleFormatter,
  customOptionFormatter,
  customTriggerFormatter,
  prefix,
  suffix,
  maxVisibleOptions = 3,
  selectedOption,
  setSelectedOption,
  defaultSelectedOption,
  disabled,
  placeholder,
  loadingOptions,
}: SearchDropdownProps<T>) => {
  const intl = useIntl();
  const selected = useMemo(
    () => selectedOption ?? defaultSelectedOption,
    [selectedOption, defaultSelectedOption]
  );
  const triggerRef = useRef(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState(selected?.title ?? "");
  const [open, setOpen] = useState(false);
  const openDropdown = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  useClickOutside([triggerRef], () => {
    setOpen(false);
  });

  const filteredOptions = options.filter((option) => {
    const trimmedValue = inputValue.toLowerCase().trim();
    const title = (
      titleFormatter ? titleFormatter(option) : option.title
    )?.toLowerCase();

    if (!inputValue || inputValue === "") return true;

    if (inputValue.length === 1) {
      return title?.startsWith(trimmedValue);
    }

    return title?.includes(trimmedValue);
  });

  const itemsCount = useMemo(
    () =>
      filteredOptions.length > 0 && filteredOptions.length <= maxVisibleOptions
        ? filteredOptions.length
        : !inputValue && !filteredOptions.length
          ? 1 //none option
          : maxVisibleOptions,
    [filteredOptions.length, maxVisibleOptions]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        if (!open) {
          return openDropdown();
        }
        const currentIndex = filteredOptions.findIndex(
          (option) => option.id === selected?.id
        );
        if (e.key === "ArrowDown") {
          if (currentIndex < filteredOptions.length - 1) {
            setSelectedOption(filteredOptions[currentIndex + 1]);
          }
        } else if (e.key === "ArrowUp") {
          if (currentIndex > 0) {
            setSelectedOption(filteredOptions[currentIndex - 1]);
          }
        }
      } else if (e.key === "Escape") {
        setOpen(false);
      }
    },
    [filteredOptions, selected, open, setSelectedOption]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (!open) {
      setInputValue(selected?.title ?? "");
    }
  }, [selected, open]);

  // disable scroll into view selected item since it is losing position when options are updated
  // useEffect(() => {
  //   //scroll into view selected item
  //   const selectedElement = document.getElementById("selected");
  //   if (selectedElement && dropdownRef.current && open) {
  //     const dropdownRect = dropdownRef.current.getBoundingClientRect();
  //     const selectedRect = selectedElement.getBoundingClientRect();
  //     if (selectedRect.top < dropdownRect.top) {
  //       dropdownRef.current.scrollTo({
  //         top: selectedElement.offsetTop,
  //         behavior: "smooth",
  //       });
  //     } else if (selectedRect.bottom > dropdownRect.bottom) {
  //       dropdownRef.current.scrollTo({
  //         top:
  //           selectedElement.offsetTop -
  //           dropdownRect.height +
  //           selectedElement.offsetHeight,
  //         behavior: "smooth",
  //       });
  //     }
  //   }
  // }, [open, filteredOptions]);

  return (
    <div className="flex flex-col gap-1 relative" ref={triggerRef}>
      <button
        onClick={openDropdown}
        className={cn(
          "p-6 border border-grey-50 rounded-2xl hover:border-black outline-black",
          {
            "border-black": open,
          },
          className
        )}
      >
        {prefix}
        <div className="relative w-full">
          <input
            className={cn("bg-[inherit] outline-none cursor-pointer w-full", {
              "caret-transparent": !open && customTriggerFormatter,
            })}
            disabled={disabled}
            placeholder={open || !customTriggerFormatter ? placeholder : ""}
            value={inputValue}
            onChange={(e) => {
              if (disabled) return;
              setInputValue(e.target.value);
            }}
          />
          {customTriggerFormatter && (
            <div className="absolute top-0 left-0 right-0 rounded-2xl pointer-events-none">
              {customTriggerFormatter(selected, inputValue, open)}
            </div>
          )}
        </div>
        {suffix}
      </button>

      {loadingOptions ? (
        <div className="flex items-center justify-center h-full">
          <SpinnerIcon />
        </div>
      ) : (
        <div
          ref={dropdownRef}
          className={cn(
            "bg-white flex-col shadow-md rounded-2xl absolute w-full z-20 hidden top-[calc(100%+0.25rem)] overflow-y-auto no-scrollbar",
            {
              flex: open,
              "border border-grey-100 ": open && filteredOptions.length > 0,
            }
          )}
          style={{
            paddingTop: `${DROPDOWN_VERTICAL_PADDING_REM}rem`,
            paddingBottom: `${DROPDOWN_VERTICAL_PADDING_REM}rem`,
            height: `${itemsCount * ITEM_HEIGHT_REM + 2 * DROPDOWN_VERTICAL_PADDING_REM + DROPDOWN_BORDER_WIDTH_REM}rem`,
          }}
        >
          <SearchDropdownOption
            isSelected={selected === undefined}
            onClick={() => {
              setSelectedOption(undefined);
              setInputValue("");
              setOpen(false);
            }}
            title={intl.formatMessage({
              id: "dropdown.none",
            })}
            className="text-grey-500"
          />
          {filteredOptions.length
            ? filteredOptions.map((option) => {
                return (
                  <SearchDropdownOption
                    key={option.id}
                    id={option.id}
                    isSelected={option.id === selected?.id}
                    disabled={option.disabled}
                    onClick={
                      option.id !== selected?.id && !option.disabled
                        ? () => {
                            setSelectedOption(option);
                            setInputValue(option?.title ?? "");
                            setOpen(false);
                          }
                        : undefined
                    }
                    customOption={
                      customOptionFormatter
                        ? customOptionFormatter(option)
                        : undefined
                    }
                    title={option.title}
                  />
                );
              })
            : inputValue && (
                <div className="p-6 m-auto flex flex-col items-center gap-3">
                  <img
                    src={search}
                    alt={
                      intl.formatMessage({
                        id: "search.empty.title",
                      }) as string
                    }
                    className="w-[6.25rem] h-[6.25rem]"
                  />
                  <div className="flex flex-col items-center gap-1 max-w-[12rem] text-center">
                    <Subtitle level={2}>
                      <Copy id="search.empty.title" />
                    </Subtitle>
                    <Subtitle level={3} className="text-grey-400">
                      <Copy id="search.empty.description" />
                    </Subtitle>
                  </div>
                </div>
              )}
        </div>
      )}
    </div>
  );
};

export default SearchDropdown;
