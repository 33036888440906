import {
  DropdownOption,
  DropdownOptionGroupItem,
} from "~/components/atoms/Dropdown/Dropdown.types";

export enum ProductCategoryGroupKey {
  hair = "hair",
  skin = "skin",
}

export enum ProductItemCategoryKey {
  ht = "ht",
  prp = "prp",
  mesotherapy = "mesotherapy",
  beard = "beard",
  eyebrow = "eyebrow",
  needling = "needling",
  botox = "botox",
  filler = "filler",
  skinBooster = "skin booster",
}
export type ProductCategoryKey =
  | ProductItemCategoryKey
  | ProductCategoryGroupKey;

export type ProductCategory = {
  name: string;
  key: ProductCategoryGroupKey;
  subCategories: {
    name: string;
    key: ProductCategoryKey;
  }[];
};

export type BaseProductCategoryDropdownOption = DropdownOption & {
  key: ProductCategoryKey;
  groupKey: ProductCategoryGroupKey;
};
export type ProductCategoryDropdownOption =
  | (DropdownOption & {
      key: ProductCategoryKey;
      groupKey: ProductCategoryGroupKey;
    })
  | DropdownOptionGroupItem<BaseProductCategoryDropdownOption>;
